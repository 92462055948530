export default class SearchService {

    constructor($http) {
        'ngInject';
        this.$http = $http;
    }

    search(query) {
        if (query) {
            query = query.includes('*') ? query.replace('*', '') : query;

            return this.$http.get(SearchService.apiPath + `?query=${query}`, {cache: true})
                .then(response => response.data);
        }
    }
}

SearchService.apiPath = '/api/search';
