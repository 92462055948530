import angular from 'angular';

export default () => (array, property, reverse) => {
    let result = array.sort((object1, object2) => {
        if (angular.isUndefined(property)) {
            return object1.localeCompare(object2);
        }

        return object1[property].localeCompare(object2[property]);
    });

    return reverse ? result.reverse() : result;
};

