class PageController {
    constructor($sce) {
        'ngInject';
        this.$sce = $sce;
    }

    $onInit() {
        this.trustedHtml = this.$sce.trustAsHtml(this.data.content);
    }
}

const PageComponent = {
    controller: PageController,
    bindings: {
        data: '<'
    },
    template: require('apply-loader!ng-pug-inline-template-loader!./template.pug')
};

export default PageComponent;
