class SearchResultsController {
    constructor($state, $stateParams) {
        'ngInject';
        this.$state = $state;
        this.$stateParams = $stateParams;
    }

    $onInit() {
        this.searchText = this.$stateParams.query;
        this.displayedCollection = [];
        this.products = this.data.products;
        this.categories = this.data.categories;
        this.brands = this.data.brands;
    }

    showNoResultMessage() {
        return this.brands.length === 0 && this.categories.length === 0 && this.products.length === 0;
    }

    openProduct(item) {
        this.$state.go('product', {
            categoryID: this.$stateParams.categoryID ? this.$stateParams.categoryID : item.categoryID,
            productID: item.id,
            productSlug: item.slug
        });
    }
}

const SearchResults = {
    controller: SearchResultsController,
    bindings: {
        data: '<'
    },
    template: require('apply-loader!ng-pug-inline-template-loader!./template.pug')
};

export default SearchResults;
