export default class ContentService {
    getPage(page) {
        return new Promise(resolve => {

            switch (page) {
            case 'about':
                resolve({
                    title: 'Rólunk',
                    pageClass: 'page',
                    content: `Az Adob Kft. csak nagykereskedelmi tevékenységet végez, magánszemélyeket nem szolgálunk ki. 
                              Weben keresztül nem történik értékesítés, cookie-kat sem tárolunk. A feltüntetett árak csak jelzés értékűek, tényleges árakat személyes ajánlatban küldünk. 
                              Elérhetőség:<span class='mail' data-user='kapcsolat' data-domain='adob.hu'></span>`
                });
            }
        });
    }
}
