class ProductPagingListController {

    constructor($state, $stateParams, ProductsService) {
        'ngInject';
        this.ps = ProductsService;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.stateName = $state.current.name;
    }

    $onInit() {
        this.pagesizeOptions = [10, 25, 50, 100];
        this.setPagination();
    }

    setPagination() {
        this.pagesize = parseInt(this.data.per_page);
        this.currentPage = this.data.current_page;
    }

    onPagesizeChange() {
        this.ps.getProductsOfCategoryPaginated(this.$stateParams.categoryID, this.$stateParams.brand, this.pagesize)
            .then(data => {
                this.data = data;
                this.setPagination();
            })
            .catch(error => console.error(error));
    }

    previousPage() {
        if (this.data.prev_page_url) {
            this.ps.getPageByURL(this.data.prev_page_url)
                .then(data => {
                    this.data = data;
                    this.setPagination();
                })
                .catch(error => console.error(error));
        }
    }

    nextPage() {
        if (this.data.next_page_url) {
            this.ps.getPageByURL(this.data.next_page_url)
                .then(data => {
                    this.data = data;
                    this.setPagination();
                })
                .catch(error => console.error(error));
        }
    }

    onPageChange(keyEvent, newValue) {
        if (keyEvent.which !== 13)
            return;

        if (newValue == '') {
            return;
        }

        if (newValue <= 1 ) {
            newValue = 1;
        } else if (newValue >= this.data.last_page) {
            newValue = this.data.last_page;
        } else if (!newValue){
            newValue = this.data.current_page;
        }

        if (newValue && newValue != this.data.current_page) {

            this.ps.getProductsOfCategoryPaginated(
                this.$stateParams.categoryID,
                this.$stateParams.brand,
                this.pagesize,
                newValue
            ).then(data => {
                this.data = data;
                this.setPagination();
            }).catch(error => console.error(error));
        } else {
            this.currentPage = newValue;
        }
    }
}

const ProductPagingList = {
    controller: ProductPagingListController,
    bindings: {
        data: '<',
    },
    template: require('apply-loader!ng-pug-inline-template-loader!./template.pug')
};

export default ProductPagingList;
