/* @ngInject */
export default ($transitions, $window, $timeout, $location, $anchorScroll) => {

    // cache scroll position of each route's templateUrl
    let scrollPosCache = {};

    return {
        restrict: 'A',
        link: () => {
            $transitions.onStart({}, trans => {
                // store scroll position for the current view
                if (trans.from().name) {
                    scrollPosCache[JSON.stringify(trans.params('from'))] = [$window.pageXOffset, $window.pageYOffset];
                }

                trans.promise.finally(() => {

                    // if hash is specified explicitly, it trumps previously stored scroll position
                    if ($location.hash()) {
                        $anchorScroll();
                        // else get previous scroll position; if none, scroll to the top of the page
                    } else {
                        let prevScrollPos = scrollPosCache[JSON.stringify(trans.params('to'))] || [0, 0];

                        $timeout(() => {
                            $window.scrollTo(prevScrollPos[0], prevScrollPos[1]);
                        }, 200);
                    }
                });
            });

            /* scope.$on('$stateChangeStart', () => {
                 // store scroll position for the current view
                 if ($state.current.name) {
                     scrollPosCache[$state.current.name + JSON.stringify($state.params)] = [$window.pageXOffset, $window.pageYOffset];
                 }
             });*/

            /*scope.$on('$stateChangeSuccess', () => {
                // if hash is specified explicitly, it trumps previously stored scroll position
                if ($location.hash()) {
                    $anchorScroll();
                    // else get previous scroll position; if none, scroll to the top of the page
                } else {
                    let prevScrollPos = scrollPosCache[$state.current.name + JSON.stringify($state.params)] || [0, 0];

                    $timeout(() => {
                        $window.scrollTo(prevScrollPos[0], prevScrollPos[1]);
                    }, 0);
                }
            });*/
        }
    };
};
