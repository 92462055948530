// Core Angular
import angular from 'angular';
import modules from './modules';

import config from './config';
import routing from './routes';
import appServices from './services';
import appFactories from './factories';
import appFilters from './filters';
import appDirectives from './directives';
import appComponents from './components';

let app = angular.module('adob', modules)
    .config(config)
    .config(routing);

appServices(app);
appFactories(app);
appFilters(app);
appDirectives(app);
appComponents(app);
