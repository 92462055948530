//App dependencies
import uirouter from '@uirouter/angularjs';
import smarttable from 'angular-smart-table';
import ngAnimate from 'angular-animate';
import ngTouch from 'angular-touch';
import ngSanitize from 'angular-sanitize';
import ngLoadingBar from 'angular-loading-bar';
import ngAria from 'angular-aria';
import ngImageGallery from 'ng-image-gallery';

export default [
    ngAria,
    ngTouch,
    ngAnimate,
    ngSanitize,
    ngLoadingBar,
    uirouter,
    smarttable,
    ngImageGallery,
];
