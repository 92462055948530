export default class CategoryService {
    constructor($http) {
        'ngInject';
        this.$http = $http;
    }
    
    getCategories() {
        return this.$http.get(CategoryService.apiPath.CATEGORIES, {cache: true})
            .then(response => response.data.categories);
    }
    getCategory(id) {
        return this.$http.get(CategoryService.apiPath.CATEGORY + `/${id}`, {cache: true})
            .then(response => response.data);
    }
}

CategoryService.apiPath = {
    CATEGORIES: 'api/categories',
    CATEGORY: 'api/singlecategory'
};
