import AppComponent from './appComponent';
import boxSelect from './boxSelect';
import breadcrumbs from './breadcrumbs';
import categorySelector from './categorySelector';
import commonBrands from './Brands/commonBrands';
import imagePlaceholder from './imagePlaceholder';
import mainBrands from './Brands/mainBrands';
import mainCategories from './mainCategories';
import product from './product';
import page from './page';
import productList from './productList';
import productPagingList from './productPagingList';
import searchResults from './searchResults';

export default app => {
    app.component('appComponent', AppComponent);
    app.component('boxSelect', boxSelect);
    app.component('breadcrumbs', breadcrumbs);
    app.component('categorySelector', categorySelector);
    app.component('commonBrands', commonBrands);
    app.component('mainBrands', mainBrands);
    app.component('mainCategories', mainCategories);
    app.component('page', page);
    app.component('product', product);
    app.component('productList', productList);
    app.component('searchResults', searchResults);
    app.component('placeholder', imagePlaceholder);
    app.component('productPagingList', productPagingList);
};
