class TitleService {
    getTitle() {
        return document.title;
    }

    setTitle(newTitle) {
        document.title = newTitle ? `${newTitle} | ADOB` : 'ADOB';
    }
}

export default TitleService;
