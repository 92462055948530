const STATE_BRAND_CATEGORY = 'brands-category';


class CategorySelectorController {
    constructor($stateParams, $state) {
        'ngInject';
        this.$state = $state;
        this.$stateParams = $stateParams;
    }

    $onInit() {
        this.stateName = this.$state.current.name;
        this.categories = this.data.children || this.data.categories;
        this.category = this.data;

        if (this.category.description2 || this.category.description) {
            this.description = this.category.description2
                ? this.category.description2
                : this.category.description;
        }
    }

    showError() {
        return !this.categories.length && !this.productListCount;
    }

    selectCategory(item) {

        if (this.stateName === STATE_BRAND_CATEGORY ) {
            this.$state.go(STATE_BRAND_CATEGORY, {
                brand: this.$stateParams.brand,
                categoryID: item.id,
                categorySlug: item.slug,
                pageTitle: item.name
            });
        } else {
            this.$state.go('categoryListing', {
                categoryID: item.id,
                categorySlug: item.slug,
                pageTitle: item.name
            });
        }
    }
}

const CategorySelector = {
    controller: CategorySelectorController,
    bindings: {
        data: '<',
        productListCount: '<'
    },
    template: require('apply-loader!ng-pug-inline-template-loader!./template.pug')
};

export default CategorySelector;
