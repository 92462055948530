import angular from 'angular';

export default () => (items, filterOn) => {

    if (filterOn === false) {
        return items;
    }

    if ((filterOn || angular.isUndefined(filterOn)) && angular.isArray(items)) {
        let newItems = [];

        let extractValueToCompare = item => {
            if (angular.isObject(item) && angular.isString(filterOn)) {
                return item[filterOn];
            } else {
                return item;
            }
        };

        angular.forEach(items, item => {
            let isDuplicate = false;

            for (let i = 0; i < newItems.length; i++) {
                if (angular.equals(extractValueToCompare(newItems[i]), extractValueToCompare(item))) {
                    isDuplicate = true;
                    break;
                }
            }
            
            if (!isDuplicate) {
                newItems.push(item);
            }

        });
        
        items = newItems;
    }
    
    return items;
};
