class AppController {
    constructor($state, $window, $location, TitleService, $transitions) {
        'ngInject';
        this.$state = $state;
        this.$location = $location;
        this.$window = $window;
        this.titleService = TitleService;
        this.$transitions = $transitions;
    }

    $onInit() {
        this.year = new Date().getFullYear();

        this.$transitions.onSuccess({}, transition => {
            this.stateName = transition.to().name;
            this.fromStateName = transition.from().name;

            this.titleService.setTitle(transition.params().pageTitle);
        });
    }

    mailTo() {
        const email = process.env.COMPANY_EMAIL;

        this.$window.open('mailto:'+ email, '_blank');
    }

    navigateTo() {
        if (this.stateName == 'home') {
            this.$state.go('about');
        } else {
            this.$state.go('home');
        }
    }

    homeActive() {
        if (this.stateName) {
            return this.stateName.includes('home') || this.stateName =='product' && this.fromStateName.includes('home');
        }
    }

    brandsActive() {
        if (this.stateName) {
            return this.stateName.includes('brand') || this.stateName =='product' && this.fromStateName.includes('brand');
        }
    }

    searchLaunch() {
        if (this.searchText && this.searchText !== '') {
            this.$state.go('search', {query: this.searchText});
            this.searchText = null;
        }
    }
}

const AppComponent = {
    controller: AppController,
    template : require('apply-loader!ng-pug-inline-template-loader!./template.pug')
};

export default AppComponent;
