class MainCategoriesController {
    constructor($state) {
        'ngInject';
        this.$state = $state;
    }

    selectCategory(item) {
        this.$state.go('categoryListing', {
            categoryID: item.id,
            categorySlug: item.slug,
            pageTitle: item.name
        });
    }
}

const MainCategories = {
    controller: MainCategoriesController,
    bindings: {
        categories: '<'
    },
    template: require('apply-loader!ng-pug-inline-template-loader!./template.pug')
};

export default MainCategories;
