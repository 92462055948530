export default class ProductsService {

    constructor($http) {
        'ngInject';
        this.$http = $http;
    }

    getSingleProduct(productID) {
        return this.$http.get(ProductsService.apiProductEndpoint + productID, {cache: true})
            .then(response => response.data.product);
    }

    getProductsOfCategory(categoryID, brandName) {
        const brandParam = encodeURI(brandName);
        const params = brandName ? categoryID + '/' + brandParam : categoryID;

        return this.$http.get(ProductsService.apiProductsEndpoint + params, {cache: true})
            .then(response => response.data.products);
    }

    getOnSaleProducts() {
        return this.$http.get(ProductsService.apiOnSaleEndpoint, {cache: true})
            .then(response => response.data.products);
    }

    // @todo: refactor .get request to pass params in config's data object
    getProductsOfCategoryPaginated(categoryID, brandName, paginateBy = 50, page) {
        let params = categoryID + '/' + paginateBy;
        params = brandName ? (params + '/' + encodeURI(brandName)) : params;

        if (page) {
            params += `?page=${page}`;
        }

        return this.$http.get(ProductsService.apiProductsOfCategoryEndpoint + params, {cache: true})
            .then(response => response.data.products);
    }

    getPageByURL(pageUrl) {
        return this.$http.get(pageUrl, {cache: true})
            .then(response => response.data.products);
    }
}

ProductsService.apiProductEndpoint = '/api/product/';
ProductsService.apiProductsEndpoint = '/api/products/';
ProductsService.apiOnSaleEndpoint = '/api/onsale';
ProductsService.apiProductsOfCategoryEndpoint = '/api/products-of-category/';
