/* @ngInject */
export default ($compileProvider, cfpLoadingBarProvider, $httpProvider) => {
    $compileProvider.commentDirectivesEnabled(false);
    $compileProvider.cssClassDirectivesEnabled(false);
    $compileProvider.debugInfoEnabled(process.env.DEBUG);

    $httpProvider.interceptors.push('rewriteApiPathInterceptor');

    cfpLoadingBarProvider.includeSpinner = false;
};
