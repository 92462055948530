class BoxSelectController {
    $onInit() {
        console.log('BoxSelectController', this.searchText); //eslint-disable-line
    }
}

const BoxSelect = {
    controller: BoxSelectController,
    bindings: {
        data: '<',
        type: '@',
        sortColumn: '@',
        itemName: '@',
        searchText: '<'
    },
    template: require('apply-loader!ng-pug-inline-template-loader!./template.pug')
};

export default BoxSelect;
