export default () => ({
    request: config => {
        // handle absolute URL request
        if (!config.url.startsWith('http') && !config.url.startsWith('//')) {
            config.url = config.url.startsWith('/') ? config.url : '/' + config.url;
            config.url = '//' + process.env.API_URL + '/v2' + config.url;
        }

        return config;
    }
});
