export default class BreadcrumbsService {
    constructor($http) {
        'ngInject';
        this.$http = $http;
    }
    
    getBreadcrumbs(categoryId) {
        if (categoryId) {
            return this.$http.get(BreadcrumbsService.apiPath + categoryId, {cache: true})
                .then(response => response.data.breadcrumbs);

        } else {
            return [];
        }
    }
}

BreadcrumbsService.apiPath = 'api/breadcrumbs/';

