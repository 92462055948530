import angular from 'angular';

export default () => (items, field, reverse) => {
    let filtered = [];

    angular.forEach(items, item => filtered.push(item));

    filtered.sort(
        (a, b) => a[field].toLowerCase() > b[field].toLowerCase() ? 1 : -1
    );

    if (reverse) filtered.reverse();

    return filtered;
};

