import {escapeRegExp} from '../../utilities/helpers';

/* @ngInject */
export default ($sce) => (text = '', phrase) => {
    if (phrase) {

        phrase = escapeRegExp(phrase);
        text = text.replace(
            new RegExp('(' + phrase + ')', 'gi'),
            '<span class="highlighted">$1</span>'
        );
        text = $sce.trustAsHtml(text);
    }

    return text;
};
