const ImagePlaceholder = {
    bindings: {
        width: '@',
        height: '@',
        alt: '@'
    },
    template: `<svg 
        class="placeholder"
        role="img" aria-label="{{::$ctrl.alt}}"
        ng-attr-width="{{::$ctrl.width}}" 
        ng-attr-height="{{::$ctrl.height}}">
            <title>{{::$ctrl.alt}}</title>
        </svg>`
};

export default ImagePlaceholder;
