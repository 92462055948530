export default () => (obj) => {

    if (obj) {
        let result = [];
        // @todo: possible use Object.value instead
        for (let [k, v] of Object.entries(obj)) { //eslint-disable-line
            result.push(v);
        }

        return result;
    }

    return obj;
};
