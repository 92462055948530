const STATE_BRAND_CATEGORY = 'brands-category';
const BREADCRUMB_START_LABEL = 'Start';

class BreadcrumbsController {
    constructor($state, $transitions, $stateParams) {
        'ngInject';
        this.$state = $state;
        this.$stateParams = $stateParams;
    }

    $onInit() {
        this.initAllProductsLink();

        this.currentState = this.$state.current.name;
        this.start = BREADCRUMB_START_LABEL;
        this.isCategoryPage = this.currentState.includes('category');
        this.isAllproductPage = this.currentState.includes('allproduct');

        if (this.$state.current.name !== 'product') {
            this.start = this.$state.params.brand ? this.$state.params.brand : BREADCRUMB_START_LABEL;
        } else {
            if (this.$state.params.prevState === STATE_BRAND_CATEGORY) {
                this.start = this.$state.params.brand || BREADCRUMB_START_LABEL;
            } else {
                this.start = BREADCRUMB_START_LABEL;
            }
        }

    }

    initAllProductsLink() {
        this.categories = this.getCategories();

        if (this.categories) {
            this.productsCount = this.getProductsCount();
            this.showAllProductsLink = this.categories.length > 0;
        }
    }

    getProductsCount() {
        let sum = 0;
        if (this.categories) {
            sum += this.categories.reduce((acc, item) => acc + parseInt(item.active_products, 10), 0);
        }
        if (this.products) {
            sum += this.products.length;
        }

        return sum;
    }

    getCategories() {
        let categories = null;
        if (this.categories) {
            categories = this.categories.children || this.categories.categories;
        }
        return categories;
    }

    goToStart() {
        if (this.start != BREADCRUMB_START_LABEL) {
            this.$state.go(STATE_BRAND_CATEGORY, {brand: this.start, categoryID: null, categorySlug: null});
        } else {
            this.$state.go('home');
        }
    }

    goToCategory(item) {
        if (this.start != BREADCRUMB_START_LABEL) {
            this.$state.go(STATE_BRAND_CATEGORY, {
                brand: this.start,
                categoryID: item.id,
                categorySlug: item.slug});
        } else {
            this.$state.go('categoryListing', {
                categoryID: item.id,
                categorySlug: item.slug
            });
        }
    }

    goBackToCategoryListing() {
        if (this.start != BREADCRUMB_START_LABEL) {
            this.$state.go(STATE_BRAND_CATEGORY, {
                brand: this.start,
                categoryID: this.$stateParams.id,
                categorySlug: this.$stateParams.categorySlug
            });
        } else {
            this.$state.go('categoryListing', {
                categoryID: this.$stateParams.categoryID,
                categorySlug: this.$stateParams.categorySlug
            });
        }
    }

    goToAllProductPageOfCategory() {
        if (this.stateName === STATE_BRAND_CATEGORY ) {
            this.$state.go('allproduct-brand', {
                brand: this.$stateParams.brand,
                categoryID: this.$stateParams.categoryID,
                categorySlug: this.$stateParams.categorySlug
            });
        } else {
            this.$state.go('allproduct', {
                categoryID: this.$stateParams.categoryID,
                categorySlug: this.$stateParams.categorySlug
            });
        }

    }
}

const Breadcrumbs = {
    bindings: {
        data: '<',
        categories: '<',
        products: '<',
    },
    controller: BreadcrumbsController,
    template: require('apply-loader!ng-pug-inline-template-loader!./template.pug')
};

export default Breadcrumbs;
