export default class BrandService {
    constructor($http) {
        'ngInject';
        this.$http = $http;
    }

    getMainBrands() {
        return this.getBrands('main')
            .then(response => response.data.mainBrands);
    }

    getCommonBrands() {
        return this.getBrands('common')
            .then(response => response.data.commonBrands);
    }

    getBrands(type = null) {
        return this.$http.get(BrandService.apiPath, {cache: true, params: { type }})
            .then(response => response);
    }

    getBrandCategories(brand, catID) {
        let promise;
        const brandParam = encodeURI(brand);
        const params = catID ? brandParam + '/' + catID : brandParam;

        promise = this.$http.get(BrandService.apiPath + '/' + params, {cache: true})
            .then(response => response.data);

        return promise;
    }
}

BrandService.apiPath = '/api/brands';
