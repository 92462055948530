class MainBrandsController {
    constructor($state) {
        'ngInject';
        this.$state = $state;
    }

    selectBrand(brandItem) {
        this.$state.go('brands-category', {
            brand: brandItem.name,
            pageTitle: brandItem.name
        });
    }
}

const mainBrands = {
    bindings: {
        brands: '<'
    },
    controller: MainBrandsController,
    template: require('apply-loader!ng-pug-inline-template-loader!./template.pug')
};

export default mainBrands;
