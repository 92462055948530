//main style
require('./main.scss');

//3rd party dependencies
require('jquery');

var $ = require('jquery');
window.jQuery = $;
window.$ = $;

require('bootstrap');

//main app
require('./app/app');