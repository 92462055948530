import BrandService from './BrandService';
import BreadcrumbsService from './BreadcrumbsService';
import CategoryService from './CategoryService';
import ContentService from './ContentService';
import ProductsService from './ProductsService';
import SearchService from './SearchService';
import TitleService from './TitleService';

export default app => {
    app.service('BrandService', BrandService);
    app.service('BreadcrumbsService', BreadcrumbsService);
    app.service('CategoryService', CategoryService);
    app.service('ContentService', ContentService);
    app.service('ProductsService', ProductsService);
    app.service('SearchService', SearchService);
    app.service('TitleService', TitleService);
};
