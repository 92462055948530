class CommonBrandsController {
    constructor($state) {
        'ngInject';
        this.$state = $state;
        this.myFilter = item => item.data.categories.length > 0; //@todo ez a filter igy lehet nem jo
    }

    selectBrand(brand) {
        this.$state.go('brands-category', {
            brand: brand.name,
            pageTitle: brand.name
        });
    }
}

const commonBrands = {
    bindings: {
        brands: '<'
    },
    controller: CommonBrandsController,
    template: require('apply-loader!ng-pug-inline-template-loader!./template.pug')
};

export default commonBrands;
