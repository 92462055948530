import ToArrayFilter from './toArrayFilter';
import UniqueFilter from './uniqueFilter';
import HighlightFilter from './highlightFilter';
import orderObjectByFilter from './orderObjectByFilter';
import orderByStringFilter from './orderByStringFilter';

export default app => {
    app.filter('toArray', ToArrayFilter);
    app.filter('unique', UniqueFilter);
    app.filter('highlight', HighlightFilter);
    app.filter('orderObjectBy', orderObjectByFilter);
    app.filter('orderByString', orderByStringFilter);
};
